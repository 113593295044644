@import '../base/variables';
@import '../tools/mixins';
@import '../../../../../../../node_modules/bootstrap/scss/mixins/breakpoints';

/*------------------------------------*\
		Modules
\*------------------------------------*/

.mod-slider {
  position: relative;
  height: 570px;
  margin-bottom: 50px;

  @include media-breakpoint-down(md) {
    height: 450px;
  }

  .slide {
    position: relative;
    height: 570px;
    cursor: pointer;

    @include media-breakpoint-down(md) {
      height: 450px;
    }

    .overlay {
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      background: linear-gradient(90deg,rgba(#5d301e, .4),hsla(0,0%,100%,.001));
    }

    .content {
      position: relative;

      .title {
        margin-bottom: 50px;
        font-size: 80px;
        line-height: 60px;
        color: #fff;

        @include media-breakpoint-down(md) {
          margin-bottom: 25px;
          font-size: 54px;
        }
      }

      .background-title {
        left: 0;
        color: rgba(#fff, .05);

        @include media-breakpoint-down(md) {
          font-size: 120px;
        }
      }

      .description {
        margin-bottom: 50px;
        color: #fff;

        @include media-breakpoint-down(md) {
          margin-bottom: 25px;
        }
      }
    }

    .background-image {
      @include cover-image;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .owl-dots {
    bottom: 30px;
    left: 0;
    right: 0;
    text-align: center;

    .owl-dot {
      display: inline-block;
      height: 10px;
      width: 10px;
      margin-left: 5px;
      margin-right: 5px;
      border: 1px solid #fff;
      border-radius: 50%;
      @include animation-transition;

      &.active, &:hover {
        background-color: #fff;
      }
    }
  }

  .owl-nav {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;

    @include media-breakpoint-down(sm) {
      display: none;
    }

    .owl-prev, .owl-next {
      background-color: transparent;
      border-radius: 50%;
      width: 34px;
      height: 34px;
      color: #fff;
      font-size: 24px;
      line-height: 32px;
      text-align: center;
      @include animation-transition;

      &:hover {
        background-color: $orange;
      }

      &.disabled {
        display: none;
      }

      img {
        height: 20px;
      }
    }

    .owl-prev {
      float: left;

      img {
        margin-left: -1px;
      }
    }

    .owl-next {
      float: right;

      img {
        margin-right: -1px;
      }
    }
  }
}

.mod-products-list {
  position: relative;
  text-align: center;
  margin-top: 110px;
  padding-bottom: 150px;
  background-image: url('#{$img-dir}mod-products-list-background.jpg');
  background-position: right -315px bottom -165px;
  background-size: 1099px 672px;
  background-repeat: no-repeat;
  z-index: 1;

  @include media-breakpoint-down(md) {
    margin-top: 70px;
    background-position: right -315px bottom 0px;
  }

  .background-title {
    left: 0;
    right: 0;
  }

  .introduction {
    margin-top: 18px;
    margin-bottom: 50px;
    color: $text-color;
    font-size: 16px;

    @include media-breakpoint-down(md) {
      margin-bottom: 20px;
    }
  }

  .owl-carousel {
    margin-bottom: 25px;

    .owl-stage {
      padding-top: 20px;
      padding-bottom: 40px;
    }

    .product {
      position: relative;
      height: 360px;
      border-radius: 5px;
      background: linear-gradient(0deg, #ffad4d, #fbc98e);
      overflow: hidden;
      @include animation-transition;

      .name {
        padding-top: 30px;
        color: #fff;
        font-size: 24px;
        font-family: Poppins;
        font-weight: normal;
      }

      .price {
        color: #fff;
        font-size: 16px;
      }

      .image {
        position: absolute;
        width: auto;
        max-width: 100%;
        max-height: 230px;
        bottom: 25px;
        left: 0;
        right: 0;
        margin: auto;
      }

      .hover {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        bottom: -100%;
        background: linear-gradient(transparent, $secondary);
        border-radius: 5px;
        @include animation-transition;

        .btn {
          position: absolute;
          padding: 18px 45px;
          width: 170px;
          margin: auto;
          left: 0;
          right: 0;
          bottom: 40px;
          border: 1px solid #fff;
          color: #fff;

          &:hover, &:focus, &:active {
            background-color: #fff;
            color: $secondary;
          }
        }
      }

      &:hover {
        transform: translateY(-20px);
        box-shadow: 0px 17px 40px 0px rgba(0, 0, 0, 0.36);

        .hover {
          bottom: 0;
        }
      }
    }

    .owl-dots {
      bottom: -10px;
      left: 0;
      right: 0;
      text-align: center;

      .owl-dot {
        display: inline-block;
        height: 10px;
        width: 10px;
        margin-left: 5px;
        margin-right: 5px;
        border: 1px solid $primary;
        border-radius: 50%;
        @include animation-transition;

        &.active, &:hover {
          background-color: $primary;
        }
      }
    }
  }
}

.mod-brown-banner {
  height: 500px;
  background: #57433c;

  @include media-breakpoint-down(md) {
    height: 550px;
  }

  @include media-breakpoint-down(sm) {
    height: auto;
  }

  .brown-banner-background-left {
    position: absolute;
    top: -116px;
    left: -20px;
    height: 950px;
    opacity: .03;
  }

  .brown-banner-background-right {
    position: absolute;
    bottom: 5px;
    right: 0px;
    height: 950px;
    opacity: .03;
  }

  .image {
    position: absolute;
    top: 25px;
    right: 50%;

    @include media-breakpoint-up(md) {
      max-width: 100%;
    }

    @include media-breakpoint-down(sm) {
      position: relative;
      width: 100%;
      top: 0;
      right: 0;
    }

    &.contain {
      height: 400px;

      @include media-breakpoint-down(sm) {
        height: auto;
        margin-bottom: 30px;
      }
    }

    &.overflow {
      height: 570px;
      top: -40px;
      z-index: 1;

      @include media-breakpoint-down(sm) {
        height: auto;
        width: calc(100% + 80px);
        left: -40px;
      }
    }
  }

  .title {
    margin-bottom: 20px;
    color: #ffffff;
  }

  .description {
    margin-bottom: 30px;
    color: #ffffff;
  }

  .btn {
    border-color: #fff;
    background-color: transparent;

    @include media-breakpoint-down(sm) {
      margin-bottom: 20px;
    }

    &:hover, &:active, &:focus {
      color: $primary;
      background: #fff;
    }
  }
}

.mod-savoir-faire {
  position: relative;
  text-align: center;
  padding-top: 110px;
  padding-bottom: 150px;
  background-image: url('#{$img-dir}mod-savoir-faire-background.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;

  @include media-breakpoint-down(sm) {
    padding-bottom: 90px;
  }

  .background-title {
    left: 0;
    right: 0;
  }

  .introduction {
    margin-top: 22px;
    margin-bottom: 50px;
    color: #777777;
  }

  .owl-carousel {
    .block {
      position: relative;
      height: 290px;
      padding: 28px 20px;
      border-radius: 20px;
      background: #ffffff;

      .name {
        color: $primary;
        font-size: 24px;
        font-family: Poppins;
        font-weight: normal;
      }

      .description {
        color: $text-color;
      }

      .image {
        width: auto;
        height: 100px;
        max-width: 100%;
        margin: auto;
        margin-bottom: 2px;
      }
    }

    .owl-dots {
      bottom: -10px;
      left: 0;
      right: 0;
      text-align: center;

      .owl-dot {
        display: inline-block;
        height: 10px;
        width: 10px;
        margin-left: 5px;
        margin-right: 5px;
        border: 1px solid $primary;
        border-radius: 50%;
        @include animation-transition;

        &.active, &:hover {
          background-color: $primary;
        }
      }
    }
  }
}

.mod-products-categories {

  .background-image-left, .background-image-right {
    @include cover-image;
    position: absolute;
    width: calc(50% - 365px);

    @include media-breakpoint-down(lg) {
      width: calc(50% - 395px);
    }

    @include media-breakpoint-down(md) {
      width: calc(50% - 300px);
    }

    @include media-breakpoint-down(sm) {
      width: 50%;
    }
  }

  .background-image-left {
    left: 0;
  }

  .background-image-right {
    right: 0;
  }

  .content-wrapper {
    position: relative;
    min-height: 660px;
    padding: 65px 100px;
    background-color: #3e3735;

    @include media-breakpoint-down(md) {
      padding: 65px;
    }

    @include media-breakpoint-down(sm) {
      width: calc(100% + 30px);
      margin-left: -15px;
      padding: 45px 20px;
    }

    &:before, &:after {
      display: block;
      position: absolute;
      content: "";
      width: 8px;
      height: 100%;
      top: 0;
      background: url('#{$img-dir}mod-products-list-battlements.png');
      background-repeat: repeat;
    }

    &:before {
      left: -8px;
    }

    &:after {
      right: -8px;
      transform: rotate(180deg);
    }

    > .main-picto {
      height: 60px;
      margin-bottom: 40px;
    }

    > .big-title {
      color: #fff;
      margin-bottom: 19px;
    }

    > .introduction {
      margin-bottom: 50px;
      color: rgba(#e5e5e5, .5);
    }

    .categories {
      margin-bottom: 90px;

      @include media-breakpoint-down(md) {
        margin-bottom: 60px;
      }

      .category {
        @include media-breakpoint-down(md) {
          margin-bottom: 30px;
        }

        a {
          display: block;

          .picto {
            max-height: 50px;
            max-width: 45px;
          }

          .name {
            margin-top: 15px;
            font-size: 32px;
            font-family: "Amatic SC";
            color: #ffffff;
            text-transform: uppercase;
          }
        }
      }
    }
  }
}

.mod-last-articles {
  position: relative;
  text-align: center;
  padding-top: 110px;
  margin-bottom: 100px;

  @include media-breakpoint-down(sm) {
    padding-top: 90px;
  }

  .background-title {
    left: 0;
    right: 0;
  }

  .introduction {
    margin-top: 22px;
    margin-bottom: 50px;
    color: #777777;
  }

  #list-articles {
    margin-bottom: 55px;

    .owl-dots {
      bottom: -10px;
      left: 0;
      right: 0;
      text-align: center;

      .owl-dot {
        display: inline-block;
        height: 10px;
        width: 10px;
        margin-left: 5px;
        margin-right: 5px;
        border: 1px solid $primary;
        border-radius: 50%;
        @include animation-transition;

        &.active, &:hover {
          background-color: $primary;
        }
      }
    }
  }
}